.deal-stat-overview-deal-type-tag{
    font-weight: bold;
    font-size: 14px !important;
    border-radius: 7px !important;
}

.deal-stat-overview-deal-type-text{
    margin-top: -5px;
}

.deal-stat-overview-doc-icon-wrapper{
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #dedffe;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deal-stat-overview-doc-icon{
    font-size: 36px;
    color: #6263b5 !important;
}