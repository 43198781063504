/* CSS for the button */
.source-button {
  cursor: pointer;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent;
  width: 40px;
  height: 40px;
}

.source-button img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create-contact-error-card {
  border: 2px solid #e74a3b;
  background-color: #e74a3b22;
  border-radius: 10px;
  padding: 12px;
}

.create-contact-error-card-text {
  font-size: 16px;
  font-weight: 500;
  color: #e74a3b;
}
