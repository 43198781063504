.contact-file-preview-wrapper{
    width: 140px;
    background-color: whitesmoke;
    height: 110px;
    min-height: 100px;
    border-radius: 10px;
    outline: 1px solid hsl(0, 0%, 89%);
}

.contact-file-name-wrapper{
    height: 110px;
}

.ant-image{
    height: 100% !important;
    border-radius: 10px !important;
}

.ant-image-mask{
    border-radius: 10px !important;
}

.contact-files-default-file{
    font-size: 32px !important;
}
.contact-files-default-file-text{
    font-size: 12px !important;
}