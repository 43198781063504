.table-row-light{
    background-color: #fff;
}

.table-row-dark{
    background-color: #fcfdff;
}

.high-value-deals-closed{
    background-color: #d8fae1;
    color: #87c39f;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.high-value-deals-open{
    background-color: #f6d5dc;
    color: #b95673;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}