.deal-value-trend {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 16px;
}

.deal-value-up-trend {
  background-color: #d7f9e1;
  color: #7bbb96;
}

.deal-value-down-trend {
  background-color: #fed6df;
  color: #b65370;
}

.deal-value-icon{
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 50px;   
    display: flex;
    align-items: center;
    justify-content: center;
}

.deal-value-tag{
  font-weight: bold;
  font-size: 14px !important;
  border-radius: 7px !important;
}

.deal-value-left-border{
  border-left: 2px solid #f8f8f8;
}