.phonenumberbox-contact-name{
    color: #7586e0;    
    font-weight: 600;
    cursor: pointer;
}

.phonenumberbox-contact-name:hover{
    text-decoration: underline;
}

.phonenumberbox-contact-number{
    margin-top: -5px !important;
    font-size: 20px;   
    font-weight: bold;
    width: 217px;
}

.phonenumberbox-wrapper{
    background-color: white;
    padding: 5px;
    border-radius: 15px;
    margin-bottom: 12px;
    box-shadow: 0px 10px 10px rgba(0,0,0,.1) !important;
    height: 70px;
    width: 340px;
}