.head-color {
    color:  #61738f !important;   
}

.icon-size{
    font-size: 20px !important;
}

.no-shadow{
    box-shadow: none !important;
}

.pointer{
    cursor: pointer !important;
}

.sider{
    height: 100vh !important;
    position: sticky !important;
    top: 0  !important;
    left: 0 !important;
    bottom: 0 !important;
    z-index: 999 !important;
    border-right: 1px solid hsl(0, 0%, 89%) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.fixed-sider{
    height: 100vh !important;
    position: fixed !important;
    z-index: 999;
    z-index: 999 !important;
    border: none !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.top-margin-10{
    margin-top: 10px !important;
}

.top-margin-20{
    margin-top: 20px !important;
}

.bottom-margin-12{
    margin-bottom: 12px !important;
}
.bold{
    font-weight: bold !important;
}

.chart-card{
    margin: 10 !important;
    height: 100% !important;
    background: #836df8 !important;
}

.chart-card-wrapper{
    padding: 2.5px ;
    position: relative !important; 
    margin: 10px 0px !important;
}

.menu-child{
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    color: #626d8d !important;
}

.menu-child-active{
    color: #fff !important;

}

.auto-overflow{
    overflow-y: auto !important;
}

.full-height{
    min-height: 100vh !important;
}

.company-avatar{
    background: #c6cfd6 !important;
    color:#607790 !important;
    display:flex !important;
    align-items:center !important;
     justify-content: center !important
}

.custom-margin-0-10{
    margin: 0px 10px;
}

.custom-margin-0-6{
    margin: 0px 6px;
}

.side-items-wrapper{
    min-height: 50px !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
}

.logo{
    height: 40px !important;
    cursor: pointer;
}

.side-menu-menu-item-description{
    font-weight: 500;
    font-size: 16px;
    line-height: 0%;
}


.side-menu-icon-new{
    font-size: 30px !important;
    color: #613eff;
}

.side-menu-button{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:12px;
}

.side-menu-button-icon{
    width: 20px;
    font-size: 22px;
}

.user-info-wrapper{
    width:130px;
}
.user-info-email{
    margin-top: -10px;
}