.profile-picture-wrapper{
    position: relative;
}

.profile-picture-wrapper:hover .upload-profile-picture-wrapper{
    opacity: 1;
}

.upload-profile-picture-wrapper{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    opacity: 0;
    transition: .1s all ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000a5;
    gap: 2px;
}

.button-left-radius{
    border-radius: 0px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.button-right-radius{
    border-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}