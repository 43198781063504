.deal-stat-table-text{
    font-weight: 500;
}

.deal-stat-table-text-bold{
    font-weight: bold !important;
}

.deal-stat-primary-box{
    width: 200px;
    background-color: #e5e9ff;
    padding: 10px;
    margin: 6px;
    border-radius: 10px;
}

.deal-stat-primary-box-text{
    color: #323e56 !important;
    font-size: 16px;
}

.ant-table-row {
    border-bottom: none !important;
}

.deal-stat-secondary-text{
    color: #888b90;
    font-weight: 600;
}

.deal-stat-thin-secondary-text{
    color: #888b90;
}

.deal-stat-card-title{
    font-size: 18px !important ;
    font-weight: 500 !important;
    color: #2e3138 !important;
}

.center-seperator-wrapper{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.center-seperator{
    width: 2px;
    height: 90%;
    background-color: #f2f2f2;

}

.deal-stat-layout-card-wrapper{
    padding: 10px;
    height: 350px;
}